import React, { Component } from 'react';
import axios from 'axios';
import Header from './Header';
import Tree from './Tree';
import Search from './Search';
import Footer from './Footer';
import { Helmet } from 'react-helmet';

class Donation extends Component {
	//const { did } = router.query;
	state = {
		donationId: '',
		totalDonors: 0,
		loaded: false,
		donation: []
	}

	componentDidUpdate(prevProps) {
    if (this.state.donationId !== this.props.match.params.donationId) {
			this.setDonationId();
    }
  }

	componentDidMount(){
		this.setDonationId();
	 }

	 async fetchDonation(){
		const url = ( process.env.NODE_ENV === 'development' ? `http://localhost:5000/donations/${this.state.donationId}` : `https://api.hospiceniagara.ca/donations/${this.state.donationId}` );
		const res = await axios.get( url );

		const { data } = await res;

		this.setState({
			totalDonors: data.totalCount,
			donation: data.donation,
			loaded: true
		});

		//document.title = 'Donation for ' +  data.donation.recipient;
	}

	 setDonationId = () => {
		const { params } = this.props.match;

    this.setState({
      donationId: params.donationId
		},
		() => {
			this.fetchDonation();
		}
		);
	}

	render() {
		let treeClass = 'view ';
		if( this.state.donation.tier ) {
			treeClass+= this.state.donation.tier + '-butterfly';
		}

		if( this.state.donation.id ) {
			treeClass+=  ' position-' +  this.state.donation.id.toString().substr(-1);
		}

		return (
			<div>
				<Header />
				<Tree treeClass={treeClass} />
				<Search />
				<div className="content-container">
					<div className="wrapper">
						{!this.state.loaded &&
							<p>Loading...</p>
						}

						{this.state.loaded && this.state.donation &&
							<>
							<Helmet>
								<title>Donation in memorial for { this.state.donation.recipient } - Hospice Niagara Butterfly Campaign 2021</title>
								<meta name="description" content={ 'A donation made in memorial for ' + this.state.donation.recipient + ' by ' + this.state.donation.sender + ' on the Hospice Niagara Butterfly Campaign Virtual Tree' } />
							</Helmet>
							<div className="donation-view-container">
								<div className="donation-view-butterfly">
									<img alt={this.state.donation.tier  +' level butterfly' } src={process.env.PUBLIC_URL + '/images/butterflies/' + this.state.donation.tier + '.png'} />
								</div>
								<div className="donation-view-content">
									<h2 className="donation-view-recipient">{this.state.donation.recipient}</h2>
									<p className="donation-view-message">{this.state.donation.message}</p>
								</div>
								<div className="donation-view-share">
									<div className="share-icon-containers">
										<span>Share</span>
										<a className="social-icon facebook" href={ 'https://www.facebook.com/sharer/sharer.php?u=https://butterfly.hospiceniagara.ca/donation/' + this.state.donation.slug } rel="noreferrer" target="_blank">Facebook</a>
										<a className="social-icon twitter" href={'https://twitter.com/intent/tweet?text=Donation%20in%20memory%20of%20' + this.state.donation.recipient + '%20%23hospiceniagara%20%23butterflycampaign%20https://butterfly.hospiceniagara.ca/donation/' + this.state.donation.slug } rel="noreferrer" target="_blank">Twitter</a>
									</div>
								</div>
							</div>
							</>
						}
					</div>
				</div>
				<Footer />

			</div>
		);
	}
}


export default Donation;
