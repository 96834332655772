import React from 'react';
import { Link } from 'react-router-dom';

const Card = (props) => {
	const data = props.data;

	return (
		<div className="butterfly-card">
			<Link to={ "/donation/" + data.slug }>
				<div className={'butterfly-card-image tier-' + data['tier'] }></div>
				<div className="butterfly-card-recipient">{ data['recipient'] }</div>
			</Link>
		</div>
	);

}

export default Card;
