import React from "react";
import Header from './Header';
import Footer from './Footer';

const NotFound = () => (
  <div>
		<Header />
    <div className="wrapper">
			<h2>Not Found!</h2>
		</div>
		<Footer />
  </div>
);

export default NotFound;