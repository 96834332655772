import { Link } from 'react-router-dom';
import logo from "../images/logo.png";


const Header = ( props ) => {

	return (
		<header>
			<div className="wrapper">
				<div className="logo">
					<Link to="/">
						<img src={logo} alt="Hospice Niagara" />
					</Link>
				</div>
				<a className="donate" target="_blank" rel="noreferrer" href="https://hospiceniagara.akaraisin.com/ui/HNButterfly">Donate</a>
			</div>
		</header>
	);

}

export default Header;
