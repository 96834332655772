import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

class Search extends Component {
	state = {
		query: '',
		results: []
	}

	async getInfo() {
		const url = ( process.env.NODE_ENV === 'development' ? `http://localhost:5000/donations/search?term=${this.state.query}` : `https://api.hospiceniagara.ca/donations/search?term=${this.state.query}` );
		const res = await axios.get( url );
		const { data } = await res;
		this.setState({
			results: data
		});
	}

	clearInfo = () => {
		this.setState({
      query: '',
      results: []
		});
	}

	handleInputChange = () => {
    this.setState({
      query: this.search.value
		},
		() => {
      if (this.state.query && this.state.query.length > 1) {
        //if (this.state.query.length % 2 === 0) {
          this.getInfo();
        //}
			}
			else {
				// clear state.
				this.clearInfo();
			}
    });
  }

	render() {
		return (
			<>
				<div className="search-container">
					<input
						placeholder="Search..."
						ref={input => this.search = input}
						onChange={this.handleInputChange}
					/>

					<ul className="autocomplete">
					{ this.state.results.map( (data) => {
							return (
								<li key={"autocomplete_" + data.id}>
									<Link onClick={ this.clearInfo } to={ "/donation/" + data.slug }>{data.recipient}</Link>
								</li>
							);
						}) }
					</ul>
				</div>
			</>
		);
	}
}

export default Search