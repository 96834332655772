import React from 'react';
import axios from 'axios';
import Header from './Header';
import Tree from './Tree';
import Search from './Search';
import Latest from './Latest';
import Footer from './Footer';
import '../App.css';

class App extends React.Component {
	constructor(){
		super();
		this.state = {
			loaded: false,
			totalDonors: 0,
			latestDonors: {}
		}
	 }

	 componentDidMount(){
			this.getData();

			document.title = 'Hospice Niagara Butterfly Campaign';
	 }

	async getData(){
		const url = ( process.env.NODE_ENV === 'development' ? `http://localhost:5000/donations/latest` : `https://api.hospiceniagara.ca/donations/latest` );
		const res = await axios.get( url );
		const { data } = await res;

		this.setState({
			latestDonors: data.donations,
			totalDonors: data.totalCount,
			loaded: true
		});
	}

	render() {

		let treeClass = "";
		if( this.state.totalDonors < 250 ) {
			treeClass = "double";
		}
		else if( this.state.totalDonors < 500 ) {
			treeClass = "double";
		}
		else if( this.state.totalDonors < 750 ) {
			treeClass = "triple";
		}
		else {
			treeClass = "quad";
		}

		return (
			<div>
				<Header />
				<Tree treeClass={treeClass} />
				<Search />
				{ !this.state.loaded ? <em>Loading...</em> : <Latest donations={this.state.latestDonors} /> }
				<Footer />
			</div>
		)
	}
}

export default App;