import React, { Component } from 'react';
import Card from './Card';

class Latest extends Component {
	render() {
		const donations = this.props.donations;
		return (
			<>
			<div className="content-container">
				<div className="wrapper">
					<h2 className="latest-donations">
						Recent Butterflies
					</h2>
					<div className="grid four">
					{ Object.keys(donations).map( (key) => {
							return (
								<Card key={donations[key]['id']} data={donations[key]} />
							);
						}) }
					</div>
			</div>
			</div>
		</>
		 );
	}
}

export default Latest;
