import footerLogo from '../images/footerLogo.png';

const footer = () => {
	return(
		<footer>
			<div className="wrapper">
				<div className="grid footer four">
					<div className="footer-item logo">
						<p><a href="https://hospiceniagara.ca" rel="noreferrer" target="_blank"><img src={footerLogo} alt="Hospice Niagara" /></a></p>
					</div>
					<div className="footer-item">
						<p>
							<strong>About Hospice Niagara</strong>
							Charitable Registration#: 89971 6294 RR0001
						</p>
						<p>
							<a href="https://hospiceniagara.akaraisin.com/ui/butterfly2020/contact" rel="noreferrer" target="_blank">Contact Us</a>
						</p>
					</div>
					<div className="footer-item">
						<p>
						<strong>Let's Get Social</strong>
						</p>
						<p>
							<a className="social-icon facebook" rel="noreferrer" href="https://www.facebook.com/hospiceniagara1/" target="_blank">Facebook</a>
							<a className="social-icon instagram" rel="noreferrer" href="https://instagram.com/HospiceNiagara" target="_blank">Instagram</a>
							<a className="social-icon twitter" rel="noreferrer" href="https://twitter.com/HospiceNiagara" target="_blank">Twitter</a>
						</p>
					</div>
					<div className="footer-item">
						<p>
							Copyright <sup>&copy;</sup> { (new Date().getFullYear()) } <br /> Hospice Niagara - Butterfly Campaign<br />
							<a href="https://spcan.com" rel="noreferrer" target="_blank">Design by Symetric_</a>
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default footer;